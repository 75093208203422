<template>
  <UiLoadingArea :is-loading="isCreating" :message="$t(`product.versions.releaseCreationInProgress`)">
    <div class="ProductReleasesIndex">
      <StickyNotification />
      <UiToolbar>
        <template #side>
          <b-button v-if="enableCreateRelease" variant="primary" @click="$refs.createReleaseDialog.toggle()">
            <span v-if="currentReleaseOrLatest === 'initial'">{{
              $t('product.versions.newReleaseFromCurrentProduct')
            }}</span>
            <span v-else>
              {{ $t('product.versions.newReleaseFromCurrentProduct') }}
              from
              <strong>{{ currentReleaseOrLatest }}</strong>
            </span>
          </b-button>
        </template>
      </UiToolbar>

      <UiTable ref="table" class="ui_table_product_release mt-3" :data="releases" :row-class-name="tableRowClassName"
        row-key="id" variant="padded" @click="edit">

        <!-- Release Number -->
        <el-table-column prop="name" width="140" :label="$t('product.versions.releaseName')">
          <template #default="{ row }">
            <div>
              <UiTagDoubleIcon :variant="releaseIcon(row)" />
            </div>
            <div v-if="isInitialProduct(row)">Initial Product</div>
            <div v-else>{{ row.versionAsString }}</div>
          </template>
        </el-table-column>

        <!-- Active and fallback indicator -->
        <el-table-column prop="name" min-width="120">
          <template #default="{ row }">

            <el-tooltip>
              <transition enter-active-class="animated fadeIn" mode="out-in" appear>
                <UiIconBadge v-if="rowIsActive(row)" :text="$t('product.versions.active')" icon="check-lg"
                  theme="primary" :hide="false"></UiIconBadge>
              </transition>
              <template slot="content">{{ $t('product.versions.tooltip.active') }}</template>
            </el-tooltip>


            <el-tooltip>
              <UiIconBadge v-if="row.isFallbackRelease" :text="$t('product.versions.fallback')" icon="layer-backward"
                theme="teal" :hide="false"></UiIconBadge>
              <template slot="content">{{ $t('product.versions.tooltip.fallback') }}</template>
            </el-tooltip>
          </template>
        </el-table-column>

        <!-- Status -->
        <el-table-column prop="status" width="180" :label="$t('product.versions.releaseStatus')">
          <template #default="{ row, $index }">
            <div v-if="!isInitialProduct(row)" class="statusContainer">
              <el-tooltip v-if="badgeInfo(row).text === 'error'">
                <UiIconBadge v-if="!isEditing($index)" :text="badgeInfo(row).text" :icon="badgeInfo(row).icon"
                  :theme="badgeInfo(row).theme" :hide="false"></UiIconBadge>
                <template slot="content">{{ $t('product.versions.tooltip.error') }}</template>
              </el-tooltip>

              <UiIconBadge v-else-if="!isEditing($index)" :text="badgeInfo(row).text" :icon="badgeInfo(row).icon"
                :theme="badgeInfo(row).theme" :hide="false"></UiIconBadge>

              <b-icon v-if="!isEditing($index) && !isExpired(row)" class="ml-2" icon="chevron-down"
                aria-hidden="true"></b-icon>

              <UiInlineDropDown
                v-if="isPublicationStatusEditable(row) && !isInitializationInProgress(row) && isEditing($index)"
                :ref="'dropdown' + $index" :label="row.publicationStatus" :value="row.publicationStatus"
                :variant="publicationStatusVariant(row.publicationStatus)"
                :items="availablePublicationStatus(row.publicationStatus).map((v) => ({ key: v, label: v }))"
                :toggle-editing="isEditing($index)"
                :handle-change="(v) => { editedRow.value.publicationStatus = v !== undefined ? v : null; }" />
            </div>
          </template>
        </el-table-column>

        <!-- Created on -->
        <el-table-column prop="createdAt" width="160" :label="$t('product.versions.releaseCreatedOn')">
          <template #default="{ row }">
            <UiFormattedDate :date="row.createdAt" />
          </template>
        </el-table-column>

        <!-- Last Revision -->
        <el-table-column prop="updatedAt" width="160" :label="$t('product.versions.releaseLastRevision')">
          <template #default="{ row }">
            <UiFormattedDate :date="row.updatedAt" />
          </template>
        </el-table-column>

        <!-- API version -->
        <el-table-column prop="api" width="160">
          <template #header>
            <span>{{ $t('product.versions.api') }}</span>
            <ui-icon-tip class="headerTooltip" icon="fas fa-question-circle"
              :message="$t('product.versions.apiTooltip')"></ui-icon-tip>
          </template>
          <template #default="{ row, $index }">
            <UiIconBadge v-for="api in row.supportedApis" :key="$index + api" :text="api" icon="clock-history"
              theme="success" :hide="false"></UiIconBadge>
          </template>
        </el-table-column>

        <!-- Releases operations -->
        <el-table-column width="140">
          <ProductReleaseRowOperations v-if="!isInitializationInProgress(row) &&
            !(
              isInitialProduct(row) &&
              row.isFallbackRelease &&
              rowIsActive(row)
            )
          " slot-scope="{ $index, row }" :is-initial-product="isInitialProduct(row)"
            :is-fallback="row.isFallbackRelease" :is-editing="isEditing($index)" :is-active="rowIsActive(row)"
            :is-expired="isExpired(row)" :is-production="isProduction(row)" :is-member="isMember()"
            :is-questionnaire="isQuestionnaire" :is-read-only="isReadonly" :show-lambda-retry="lambdaError(row)"
            :can-toggle-fallback="canToggleFallback" class="ui_buttons_operations"
            @command="(command) => handleRowItemCommand(command, $index)" />
        </el-table-column>
      </UiTable>
      <CreateReleaseDialog ref="createReleaseDialog" :active-release="currentReleaseOrLatest"
        :handle-release-creation="handleReleaseCreation" :existing-releases="existingReleaseVersions" />
    </div>
  </UiLoadingArea>
</template>

<script>
/* eslint-disable import/no-named-as-default-member */
import Product from '@/domain/model/product';
import ProductRelease, {
  PublicationStatus
} from '@/domain/model/productRelease';
import { mapGetters, mapState } from 'vuex';
import { productTypes } from '@/const/product';
import CreateReleaseDialog from './Dialog/CreateReleaseDialog';
import ProductReleaseRowOperations from './ProductReleaseRowOperations';
import ProductSnapshotMixin from './ProductSnapshotMixin';
import StickyNotification from '../StickyNotification';

const publicationStatusVariantMappings = {
  EXPIRED: 'light',
  PRODUCTION: 'default',
  DRAFT: 'default',
  fallback: 'default'
};

export default {
  name: 'ProductReleaseIndex',
  components: {
    CreateReleaseDialog,
    ProductReleaseRowOperations,
    StickyNotification
  },
  mixins: [ProductSnapshotMixin],
  props: {
    product: { type: Object, required: true },
    releases: { type: Array, required: true },
    isReadonly: { type: Boolean, required: true },
    handleReleaseCreation: { type: Function, required: true },
    handleReleaseRemoval: { type: Function, required: true },
    handleChangeStatus: { type: Function, required: true },
    handleSwitchRelease: { type: Function, required: true },
    handleToggleFallbackRelease: { type: Function, required: true },
    handleLambdaRetry: { type: Function, required: true }
  },
  data() {
    return {
      editedRow: null,
      publicationStatus: [
        PublicationStatus.DRAFT,
        PublicationStatus.PRODUCTION,
        PublicationStatus.EXPIRED
      ]
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isMember', 'isGuest', 'isOwner']),
    ...mapState('productRelease', ['isCreating']),
    existingReleaseVersions() {
      return this.releases
        .filter((r) => r.label !== undefined)
        .map((r) => ({
          ...r.version,
          label: r.label
        }));
    },
    currentReleaseOrLatest() {
      return this.currentRelease && this.currentRelease.versionAsString !== null
        ? this.currentReleaseVersion
        : 'initial';
    },
    currentRelease() {
      return this.releases.find(this.rowIsActive);
    },
    currentReleaseVersion() {
      const { version } = this.currentRelease;
      const { major, minor, patch } = version;
      return `${major}.${minor}.${patch}`;
    },
    isQuestionnaire() {
      return [
        productTypes.QUESTIONNAIRE,
        productTypes.SHADOW_QUESTIONNAIRE
      ].includes(this.product.type);
    },
    enableCreateRelease() {
      return (
        !this.isCreating &&
        (this.isAdmin(this.product.team.slug) ||
          this.isOwner(this.product.team.slug) ||
          this.isMember(this.product.team.slug))
      );
    },
    canToggleFallback() {
      return (
        this.isAdmin(this.product.team.slug) ||
        this.isOwner(this.product.team.slug)
      );
    },
    canChangeReleaseToProduction() {
      return (
        this.isAdmin(this.product.team.slug) ||
        this.isOwner(this.product.team.slug)
      );
    },
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.publicationStatus === PublicationStatus.INITIALIZATION) {
        return 'release-initialization';
      }
      return '';
    },
    rowIsActive(row) {
      return (
        row.versionAsString === this.product.version.current ||
        (!row.versionAsString && this.product.version.current === 'initial')
      );
    },
    isExpired({ publicationStatus }) {
      return ProductRelease.isExpired(publicationStatus);
    },
    isProduction({ publicationStatus }) {
      return ProductRelease.isProduction(publicationStatus);
    },
    isDraft({ publicationStatus }) {
      return ProductRelease.isDraft(publicationStatus);
    },
    isInitializationInProgress({ publicationStatus }) {
      return ProductRelease.isInitializationInProgress(publicationStatus);
    },
    isInitialProduct(product) {
      return Product.isInitialProduct(product);
    },
    isPublicationStatusEditable(product) {
      return ProductRelease.isPublicationStatusEditable({
        isAdmin: this.isAdmin(),
        publicationStatus: product.publicationStatus,
        isReadOnly: this.isReadonly
      });
    },

    edit(row) {
      if (
        this.isInitialProduct(row) ||
        this.isExpired(row) ||
        !this.canChangeReleaseToProduction) return;
      const rowIndex = this.releases.indexOf(row)
      this.editedRow = {
        index: rowIndex,
        value: {
          ...row
        }
      };
      setTimeout(() => {
        const refName = 'dropdown' + rowIndex;
        this.$refs[refName].$children[0].focus();
      }, 100);
    },
    editByIndex(index) {
      if (!this.isMember()) return;
      this.edit(this.getRelease(index));
    },
    isEditing(index) {
      return this.editedRow && this.editedRow.index === index;
    },
    cancel() {
      this.editedRow = null;
    },
    publicationStatusVariant(value) {
      return (
        publicationStatusVariantMappings[value] ||
        publicationStatusVariantMappings.fallback
      );
    },
    readonly(row) {
      return row.readonly || !this.isMember();
    },
    lambdaError(row) {
      // This might change in the future taking into account the updated_at timestamp
      return row.lambdaPublished && row.lambdaPublished.status === 'Failed';
    },
    lambdaPending(row) {
      return row.lambdaPublished && row.lambdaPublished.status === "Pending";
    },
    handleRowItemCommand(command, index) {
      const row = this.getRelease(index);
      const isActiveRelease = this.rowIsActive(row);
      // @todo Switch/Reset command did not mutate the product state
      const c = {
        edit: () => this.editByIndex(index),
        delete: () => this.handleReleaseRemoval(row.id, isActiveRelease, row.publicationStatus === PublicationStatus.DRAFT),
        save: () => this.save(),
        cancel: () => this.cancel(),
        switch: () => this.handleSwitchRelease(row),
        toggleFallbackRelease: () =>
          this.handleToggleFallbackRelease(row.productId, row.id, row.publicationStatus === PublicationStatus.PRODUCTION && !this.lambdaError(row)),
        retryLambda: () => this.handleLambdaRetry(row),
      }[command];
      if (!c) throw new Error(`Unknown command ${command}`);
      c();
    },
    save() {
      const currentRelease = this.releases[this.editedRow.index];
      if (
        this.isPublicationStatusEditable(currentRelease) &&
        !this.isInitializationInProgress(currentRelease)
      ) {
        const { id, publicationStatus } = this.editedRow.value;
        if (publicationStatus !== currentRelease.publicationStatus) {
          this.handleChangeStatus(
            id,
            publicationStatus,
            currentRelease.isFallbackRelease
          );
        }
      }
      this.editedRow = null;
    },
    getRelease(index) {
      const row = this.releases[index];
      if (!row) throw new Error('Invalid row index');
      return row;
    },
    availablePublicationStatus(currentPublicationStatus) {
      if (currentPublicationStatus === PublicationStatus.PRODUCTION) {
        return this.publicationStatus.filter(
          (e) => e !== PublicationStatus.DRAFT
        );
      }
      return this.publicationStatus;
    },
    badgeInfo(row) {
      const info = {
        text: row.publicationStatus,
        icon: "clock-history",
        theme: "gray"
      };

      if (this.isProduction(row)) {
        if (!this.lambdaError(row) & !this.lambdaPending(row)) {
          info.icon = "check-circle-fill";
          info.theme = "success";
        }

        if (this.lambdaError(row)) {
          info.text = "error"
          info.icon = "x-circle-fill";
          info.theme = "danger";
        }
      }

      if (this.isDraft(row)) {
        info.icon = "pencil-fill";
        info.theme = "apache";
      }

      if (this.isExpired(row)) {
        info.icon = "hourglass-bottom";
      }

      return info;
    }
  },
};
</script>

<style>
.el-tooltip__popper {
  max-width: 250px;
}
</style>

<style lang="scss" scoped>
@import '@axatechlab/assets/scss/_variables';

.ui_table_product_release::v-deep {
  .release-initialization {
    cursor: not-allowed;
    pointer-events: none;

    >td {
      cursor: not-allowed;
      pointer-events: none;
      background-color: $color-placehoder;
    }
  }
}

.statusContainer {
  cursor: pointer;
}
</style>
